import numberMixin from '@/mixins/numberMixin';
import paymentMixin from '@/mixins/paymentMixin';

export default {
  mixins: [numberMixin, paymentMixin],
  data () {
    return {
      rejectionEnumeration: {
        REJECTION_REASON_MISSING_ORDER_REFERENCE: 'REJECTION_REASON_MISSING_ORDER_REFERENCE', // must be the first reason to be shown
        REJECTION_REASON_MISSING_CUSTOMER_REFERENCE: 'REJECTION_REASON_MISSING_CUSTOMER_REFERENCE', // must be the second one
        REJECTION_REASON_DEBT_ALREADY_PAID: 'REJECTION_REASON_DEBT_ALREADY_PAID', // and the third one
        REJECTION_REASON_BROKEN_ITEM: 'REJECTION_REASON_BROKEN_ITEM',
        REJECTION_REASON_BROKEN_ITEMS: 'REJECTION_REASON_BROKEN_ITEMS',
        REJECTION_REASON_INCORRECT_COMPANY_REFERENCE: 'REJECTION_REASON_INCORRECT_COMPANY_REFERENCE',
        REJECTION_REASON_INCORRECT_TOTAL_AMOUNT: 'REJECTION_REASON_INCORRECT_TOTAL_AMOUNT',
        REJECTION_REASON_ITEMS_NOT_RECEIVED: 'REJECTION_REASON_ITEMS_NOT_RECEIVED',
        REJECTION_REASON_MISSING_ITEMS: 'REJECTION_REASON_MISSING_ITEMS',
        REJECTION_REASON_OTHER: 'REJECTION_REASON_OTHER'
      },
      fileTypeEnumeration: {
        INVOICE_CREATION: 'INVOICE_CREATION'
      },
      eventEnumeration: {
        UPDATE: {
          type: 'UPDATE',
          description: (event) => {
            return this.$t('UPDATE_EVENT_DESCRIPTION', {
              initialAmount: this.applyCurrencyFilter(event.descriptionParameters.initialAmount, event.descriptionParameters.currency),
              newAmount: this.applyCurrencyFilter(event.descriptionParameters.newAmount, event.descriptionParameters.currency)
            });
          }
        },
        VALIDATION: {
          type: 'VALIDATION',
          description: (event) => {
            return this.$t('VALIDATION_EVENT_DESCRIPTION', { userName: event.descriptionParameters.username });
          }
        },
        CREATION: {
          type: 'CREATION',
          description: (event) => {
            if (event.documentType === 'INVOICE') {
              return this.$t('CREATION_INVOICE_EVENT_DESCRIPTION', {
                amount: this.applyCurrencyFilter(event.descriptionParameters.initialAmount, event.descriptionParameters.currency)
              });
            } else {
              return this.$t('CREATION_CREDIT_NOTE_EVENT_DESCRIPTION', {
                amount: this.applyCurrencyFilter(event.descriptionParameters.initialAmount, event.descriptionParameters.currency)
              });
            }
          }
        },
        REJECT: {
          type: 'REJECT',
          description: (event) => {
            return this.$t('REJECTION_EVENT_DESCRIPTION', { userName: event.descriptionParameters.username }) + this.$t(event.descriptionParameters.reason);
          }
        },
        PAYMENT: {
          type: 'PAYMENT',
          description: (event) => {
            if (event.documentType === 'INVOICE') {
              return this.$t('PAYMENT_INVOICE_EVENT_DESCRIPTION', {
                userName: event.descriptionParameters.username,
                amount: this.applyCurrencyFilter(event.descriptionParameters.initialAmount, event.descriptionParameters.currency),
                paymentMean: this.$t(event.descriptionParameters.paymentMean)
              });
            } else {
              return this.$t('PAYMENT_CREDIT_NOTE_EVENT_DESCRIPTION', {
                userName: event.descriptionParameters.username,
                amount: this.applyCurrencyFilter(event.descriptionParameters.initialAmount, event.descriptionParameters.currency)
              });
            }
          }
        },
        SETTLEMENT: {
          type: 'SETTLEMENT',
          description: () => { return this.$t('SETTLEMENT_EVENT_DESCRIPTION'); }
        },
        REFUND: {
          type: 'REFUND',
          description: (event) => {
            return this.$t('REFUND_EVENT_DESCRIPTION', {
              paymentId: event.descriptionParameters.paymentReference,
              amount: this.applyCurrencyFilter(event.descriptionParameters.refundedAmount, event.descriptionParameters.currency)
            });
          }
        },
        CANCELLATION: {
          type: 'CANCELLATION',
          description: (event) => {
            return this.$t('CANCELLATION_EVENT_DESCRIPTION', {
              paymentId: event.descriptionParameters.paymentReference,
              amount: this.applyCurrencyFilter(event.descriptionParameters.refundedAmount, event.descriptionParameters.currency)
            });
          }
        },
        DELETE: {
          type: 'DELETE',
          description: (event) => {
            return this.$t('DELETE_EVENT_DESCRIPTION', {
              documentId: event.documentId
            });
          }
        },
        CREATION_FAILED: {
          type: 'CREATION_FAILED'
        },
        REACTIVATION: {
          type: 'REACTIVATION',
          description: () => { return this.$t('REACTIVATION_EVENT_DESCRIPTION'); }
        },
        INACTIVATION: {
          type: 'INACTIVATION',
          description: (event) => {
            return this.$t('INACTIVATION_EVENT_DESCRIPTION', {
              documentId: event.documentId
            });
          }
        }
      },
      failureEnumeration: {
        OPENCELL_FAILURE: {
          type: 'OPENCELL_FAILURE',
          description: () => { this.$t('CREATION_FAILURE_OPENCELL_EVENT_DESCRIPTION'); }
        },
        S3_FAILURE: {
          type: 'S3_FAILURE',
          description: () => { this.$t('CREATION_FAILURE_S3_EVENT_DESCRIPTION'); }
        },
        DEFAULT_FAILURE: {
          type: 'DEFAULT_FAILURE',
          description: () => { this.$t('DEFAULT_FAILURE_EVENT_DESCRIPTION'); }
        }
      }
    };
  }
};
